<template>
  <a-modal
    title="选择用户"
    :width="800"
    :visible="visible"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <div class="container">
      <a-row>
        <a-col :span="24" class="right">
          <div class="right-content">
            <!-- 筛选 -->
            <div class="table-page-search-wrapper">
              <a-form layout="inline">
                <a-row :gutter="40">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="关键词">
                      <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                    </a-form-item>
                  </a-col>

                  <a-col :md="8" :sm="24">
                    <a-form-item label="状态">
                      <a-select v-model="queryParam.delFlag" allow-clear placeholder="请选择">
                        <a-select-option :value="1">正常</a-select-option>
                        <a-select-option :value="3">停用</a-select-option>
                        <a-select-option :value="2">已注销</a-select-option>
                        <a-select-option :value="4">违规</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-button type="primary" @click="initTableData">搜索</a-button>
                    <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                  </a-col>
                </a-row>
              </a-form>
            </div>
            <!-- 表格 -->
            <a-table
              :columns="columns"
              :data-source="data"
              :loading="loading"
              :pagination="false"
              rowKey="id"
              :row-selection="rowSelection"
            >
              <!-- 头像插槽 -->
              <span slot="avatar" slot-scope="text">
                <img :src="text" alt="" />
              </span>
              <!-- 账号状态插槽 -->
              <span slot="accountStatus" slot-scope="text">
                <a-tag :color="text == 1 ? 'green' : text == 2 ? 'red' : ''">
                  {{ text == 1 ? '正常' : text == 2 ? '违规' : '' }}
                </a-tag>
              </span>
              <!--            官方平台账号 -->
              <span slot="isPlatform" slot-scope="text">
                <a-tag :color="text == 1 ? 'red' : 'green'">
                  {{ text == 1 ? '否' : '是' }}
                </a-tag>
              </span>
            </a-table>
          </div>
        </a-col>
      </a-row>
      <!-- 分页组件 -->
      <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
        <a-pagination
          :pageSize="page.pageSize"
          :current="page.pageNo"
          show-size-changer
          :page-size-options="['10', '20', '50', '100']"
          show-quick-jumper
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="pageChange"
          @showSizeChange="sizeChange"
        >
        </a-pagination>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { customerPageList } from '@/api/modular/mallLiving/userAdmin'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '昵称',
    align: 'left',
    dataIndex: 'nickname',
    width: '180px',
  },
  {
    title: 'ID号',
    dataIndex: 'idNo',
    align: 'center',
    width: '120px',
  },

  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'left',
    width: '120px',
  },
  {
    title: '账号状态',
    dataIndex: 'accountStatus',
    key: 'accountStatus',
    align: 'left',
    width: '110px',
    scopedSlots: {
      customRender: 'accountStatus',
    },
  },
  {
    title: '官方平台账号',
    dataIndex: 'isPlatform',
    key: 'isPlatform',
    align: 'left',
    width: '110px',
    scopedSlots: {
      customRender: 'isPlatform',
    },
  },
  {
    title: '头像',
    dataIndex: 'avatar',
    align: 'left',
    width: '100px',
    scopedSlots: {
      customRender: 'avatar',
    },
  },
  {
    title: '关注基数',
    dataIndex: 'followNumBase',
    align: 'left',
    width: '120px',
  },
  {
    title: '粉丝基数',
    dataIndex: 'fansNum',
    align: 'left',
    width: '120px',
  },

  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'left',
    width: '180px',
  },
  {
    title: '修改时间',
    dataIndex: 'updateTime',
    align: 'left',
    width: '180px',
  },
  {
    title: '注销时间',
    width: '180px',
    dataIndex: 'cancellationTime',
    align: 'left',
  },
]
export default {
  name: 'chooseUser',
  data() {
    return {
      columns,
      visible: false, //modal框显示状态
      data: [],
      queryParam: {},
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        type: 'radio',
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    init() {
      this.visible = true
      this.initTableData()
    },
    handleSubmit() {
      if (!this.selectedRows.length) {
        return this.$message.info('请选择用户！')
      }
      const { nickname: name, id, mobile } = this.selectedRows[0]
      this.$parent.setUser({ name, id, mobile })
      this.handleCancel()
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
    },
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      this.selectedRows = []
      this.selectedRowKeys = []
      customerPageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style scoped>
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
  overflow: auto;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
